/** @jsxRuntime classic */
/** @jsx jsx */
import { Button, colorTheme, respondTo } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useBackgroundImageUrl } from '../hooks/useBackgroundImageUrl';
import { useQueryParams } from '../hooks/useQueryParams';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { Header } from './Header';

const headerStyle = {
  header: {
    justifyContent: 'center',
    [respondTo('lg')]: {
      justifyContent: 'start',
    },
  },
};
const containerStyle = (backgroundImageUrl) => ({
  backgroundImage: `url('${backgroundImageUrl}')`,
  backgroundSize: 'cover',
  backgroundPositionY: '-27vh',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  header: {
    justifyContent: 'center',
    height: '5em',
  },
  [respondTo('md')]: {
    paddingTop: 120,
  },
  [respondTo('lg')]: {
    justifyItems: 'end',
    display: 'grid',
    gridTemplateColumns: '1fr 24% 2fr 30% 1fr',
    height: '100vh',
    justifyContent: 'end',
  },
  '*': {
    fontFamily: 'Gilroy',
  },
  img: {
    marginTop: 30,
    marginBottom: 30,
  },
  p: { color: colorTheme.textMute },
  'main.form-container': {
    gridColumn: 4,
    maxWidth: 650,
    [respondTo('lg')]: {
      width: '100%',
      minWidth: 500,
    },
    h1: {
      fontSize: 24,
      [respondTo('sm')]: {
        fontSize: 32,
      },
    },
    p: {
      size: 16,
      width: '70%',
    },
    button: {
      size: 16,
      paddingLeft: 50,
      paddingRight: 50,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    borderRadius: 5,
    backgroundColor: 'white',
    width: '100vw',
    [respondTo('xs', 'sm')]: {
      borderRadius: 0,
      justifyContent: 'start',
      height: '100vh',
    },
    height: 490,
  },
});

export const FailedCheckoutV2 = ({ channel }) => {
  const { t, ready } = useSelfServiceTranslation();
  const history = useHistory();
  const linkId = useQueryParams().get('link');
  const updatePaymentMethod = useQueryParams().get('updatePaymentMethod');
  const backgroundImageUrl = useBackgroundImageUrl(channel);
  if (!ready) {
    return null;
  }

  const translationKey = updatePaymentMethod ? 'failedPaymentMethodUpdate' : 'failedCheckout';

  return (
    <Fragment>
      <div css={headerStyle}>
        <Header channel={channel} justifyContentDesktop={'start'} />
      </div>
      <div css={containerStyle(backgroundImageUrl)}>
        <main className="form-container">
          <img
            src="https://branding.123seguro.com/images/img_life_checkout_error.png"
            alt="shield icon representing a secure page"
          />
          <h1>{t(`paymentMethods.${translationKey}.title`)}</h1>
          <p>{t(`paymentMethods.${translationKey}.subTitle`)}</p>
          <Button
            variant="primary"
            color="success"
            css={{ marginTop: 40 }}
            onClick={() => {
              history.push(`new-payment-method?link=${linkId}`);
            }}
          >
            {t(`paymentMethods.${translationKey}.newPaymentMethod`)}
          </Button>
        </main>
      </div>
    </Fragment>
  );
};

FailedCheckoutV2.propTypes = {
  channel: PropTypes.string,
};

FailedCheckoutV2.defaultProps = {
  channel: 'prudential',
};
