/** @jsxRuntime classic */
/** @jsx jsx */
import { Button, bodyFont, colorTheme, titleFont } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../hooks/useQueryParams';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { Header } from './Header';

const mainStyles = {
  textAlign: 'center',
  paddingLeft: 8,
  paddingRight: 8,
  img: {
    marginTop: 30,
    marginBottom: 30,
  },
  h1: titleFont('md', 'serif'),
  p: { ...bodyFont('md'), color: colorTheme.textMute },
};

export const FailedCheckout = ({ channel }) => {
  const { t, ready } = useSelfServiceTranslation();
  const linkId = useQueryParams().get('link');
  const history = useHistory();

  if (!ready) {
    return null;
  }

  return (
    <Fragment>
      <Header channel={channel} />
      <main css={mainStyles}>
        <img
          src="https://branding.123seguro.com/images/img_life_checkout_error.png"
          alt="shield icon representing a secure page"
        />
        <h1>{t('paymentMethods.failedCheckout.title')}</h1>
        <p>{t('paymentMethods.failedCheckout.subTitle')}</p>
        <Button
          variant="primary"
          color="success"
          css={{ marginTop: 40 }}
          onClick={() => {
            history.push(`new-payment-method?link=${linkId}`);
          }}
        >
          {t('paymentMethods.failedCheckout.newPaymentMethod')}
        </Button>
      </main>
    </Fragment>
  );
};

FailedCheckout.propTypes = {
  channel: PropTypes.string,
};
