import config from '../../config';
import { getExpirationMonth, getExpirationYear } from './expiration-date';

export const savePaymentMethod = async ({
  type,
  linkId,
  country,
  channel,
  token,
  contextKey,
  brandName,
  cardNumber,
  cardHolder,
  dueDate,
  legalIdType,
  legalIdNumber,
  updatePaymentMethod,
}) => {
  const expiryMonth = getExpirationMonth(dueDate);
  const expiryYear = 2000 + getExpirationYear(dueDate);

  const payload = {
    type,
    linkId,
    country,
    channel,
    token,
    contextKey,
    brandName,
    cardNumber,
    cardHolder: cardHolder?.trim() || undefined,
    expiryMonth,
    expiryYear,
    legalIdType,
    legalIdNumber,
    updatePaymentMethod,
  };

  return fetch(`${config.basePath}/api/payments/payment-methods`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
    redirect: 'follow',
  })
    .then((res) => {
      if (res.ok) {
        return { ok: true, redirectUrl: res.url };
      }
      return res.json();
    })
    .then((body) => {
      if (body.ok) {
        return body;
      }

      return createError(body);
    });
};

const createError = (errorPayload) => {
  const errorFields = buildErrorFields(errorPayload);

  if (Object.keys(errorFields).length === 0) {
    return {
      ok: false,
      errorMessage: 'paymentMethods.createPaymentMethod.errorMessage',
      errorFields,
    };
  }

  return {
    ok: false,
    errorMessage: 'paymentMethods.createPaymentMethod.errorFieldMessage',
    errorFields,
  };
};

const fieldsMapping = {
  'paymentMethod.expiryMonth': {
    dueDate: 'paymentMethods.createPaymentMethodValidation.dueDateInvalid',
  },
  'paymentMethod.expiryYear': {
    dueDate: 'paymentMethods.createPaymentMethodValidation.dueDateInvalid',
  },
  'paymentMethod.cardHolderName': {
    cardHolder: 'paymentMethods.createPaymentMethodValidation.cardHolderRequired',
  },
  token: {},
};

const buildErrorFields = (errorPayload) => {
  if (!Array.isArray(errorPayload)) {
    return {};
  }

  const bodyAttributes = errorPayload[0]['invalid-body-attributes'];

  if (!bodyAttributes) {
    return {};
  }

  if (!Array.isArray(bodyAttributes)) {
    return {};
  }

  const fieldError = fieldsMapping[bodyAttributes[0].name] || {};

  return fieldError;
};
