import PropTypes from 'prop-types';
import { getChannelData } from './channel-branding';
import { Success } from './Success';
import { SuccessV2 } from './SuccessV2';

const SuccessComponentSelector = ({ match }) => {
  const channel = match.params?.channel;
  const { useNewDesign, primaryContact, primaryContactTarget } = getChannelData(channel);
  return useNewDesign ? (
    <SuccessV2
      channel={channel}
      primaryContact={primaryContact}
      primaryContactTarget={primaryContactTarget}
    />
  ) : (
    <Success channel={channel} />
  );
};

SuccessComponentSelector.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      channel: PropTypes.string,
    }),
  }),
};
SuccessComponentSelector.defaultProps = {
  match: { params: { channel: '' } },
};

export default SuccessComponentSelector;
