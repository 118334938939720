/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Button,
  colors,
  colorTheme,
  Column,
  Divider,
  Icon,
  icons,
  respondTo,
  Row,
} from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import envConfig from '../config';
import { useBackgroundImageUrl } from '../hooks/useBackgroundImageUrl';
import { useQueryParams } from '../hooks/useQueryParams';
import { countryDomain, domainLocation } from '../shared/domainResolver';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { getChannelData } from './channel-branding';
import { Header } from './Header';

const baseUrl = `${envConfig.baseUrl}.${countryDomain}`;

const styles = (backgroundImageUrl) => ({
  minHeight: '100vh',
  width: '100vw',
  backgroundImage: `url('${backgroundImageUrl}')`,
  backgroundSize: 'cover',
  backgroundPositionY: '-27vh',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  [respondTo('xs', 'lg')]: {
    justifyContent: 'center',
  },
  [respondTo('md')]: {
    paddingTop: 120,
  },
  [respondTo('lg')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 24% 2fr 30% 1fr',
    justifyItems: 'end',
    height: '100vh',
  },
  main: {
    gridColumn: 4,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 30,
    paddingTop: 40,
    alignItems: 'center',
    maxWidth: 640,
    height: '100vh',
    [respondTo('md')]: {
      height: 490,
      justifyContent: 'center',
    },
    [respondTo('lg')]: {
      minWidth: 500,
    },
    h1: {
      textAlign: 'center',
      fontSize: 24,
      [respondTo('sm')]: {
        fontSize: 32,
      },
      fontFamily: 'Gilroy',
      marginTop: 24,
      marginBottom: 24,
    },
    p: {
      textAlign: 'center',
      fontFamily: 'Gilroy',
      color: colorTheme.textMute,
    },
  },
});

const useReturnPath = (channel) => {
  const product = useQueryParams().get('product');
  const { returnPath, products } = getChannelData(channel);

  return products?.[product]?.returnPath ?? returnPath;
};

export const InvalidLinkV2 = ({ channel }) => {
  const { t, ready } = useSelfServiceTranslation();
  const {
    linkType,
    contactType,
    invalidLink,
    includeContact,
    primaryContact,
    primaryContactIntro,
    primaryContactTarget,
    secondaryContactIntro,
    secondaryContact,
    secondaryContactTarget,
  } = getChannelData(channel, domainLocation);
  const returnPath = useReturnPath(channel);
  const backgroundImageUrl = useBackgroundImageUrl(channel);

  return ready ? (
    <Fragment>
      <Header channel={channel} justifyContentDesktop={'start'} />
      <div css={styles(backgroundImageUrl)}>
        <main>
          <img
            src="https://branding.123seguro.com/images/img_life_checkout_invalid_link.png"
            alt="sin resultados"
          />
          <h1>{t(`paymentMethods.invalidLink.${invalidLink}`)}</h1>
          <p>{t(`paymentMethods.invalidLink.expiredLink.${linkType}`)}</p>
          {contactType && <p>{t(`paymentMethods.invalidLink.${contactType}`)}</p>}
          {includeContact && (
            <Row className="contact">
              <Column desktop={3} mobile={3} className="contact-image-column">
                <img
                  src="https://s3-us-west-2.amazonaws.com/branding/images/img_contactus.svg"
                  alt="contactanos"
                />
              </Column>
              <Column desktop={6} mobile={6}>
                <p>{t('paymentMethods.invalidLink.doubts')}</p>
                <Divider />
                <div className="primary-contact">
                  <div>{t(`paymentMethods.invalidLink.${primaryContactIntro}`)}</div>
                  <a href={primaryContactTarget}>{primaryContact}</a>
                </div>
                <Divider />
                <span className="secondary-contact">
                  {t(`paymentMethods.invalidLink.${secondaryContactIntro}`)}{' '}
                  <a href={secondaryContactTarget}>{secondaryContact}</a>
                </span>
              </Column>
              <Column desktop={3} mobile={3} />
            </Row>
          )}
          {returnPath && (
            <Button variant="link" size="md" className="return-button">
              <Icon size="sm" icon={icons.back} color={colors.cyan.dark} />
              <a href={`${baseUrl}/${returnPath}`}>{t('paymentMethods.invalidLink.return')}</a>
            </Button>
          )}
        </main>
      </div>
    </Fragment>
  ) : null;
};

InvalidLinkV2.propTypes = {
  channel: PropTypes.string,
};

InvalidLinkV2.defaultProps = {
  channel: '',
};
