/** @jsxRuntime classic */
/** @jsx jsx */
import { bodyFont, colorTheme, titleFont } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { Header } from './Header';
import { getChannelData } from './channel-branding';

const styles = {
  textAlign: 'center',
  '.success-content': {
    marginTop: 100,
    h1: {
      ...titleFont('md', 'serif'),
      marginTop: 40,
    },
    p: {
      ...bodyFont('md'),
      color: colorTheme.textMute,
    },
    'p:first-of-type': {
      marginBottom: 40,
    },
    '.success-image': {
      height: 100,
    },
  },
};
export const Success = ({ channel }) => {
  const { name, linkType } = getChannelData(channel);
  const { t, ready } = useSelfServiceTranslation();

  return ready ? (
    <div css={styles}>
      <Header channel={channel} />
      <div className="success-content">
        <img
          className="success-image"
          alt="success"
          src="https://s3-us-west-2.amazonaws.com/branding/images/img_success.svg"
        />
        <h1>{t('paymentMethods.success.successMessage')}</h1>
        <p>
          {t('paymentMethods.success.thankYouForEnteringData')}
          <br />
          {t('paymentMethods.success.paymentMethodSuccess')}
        </p>
        {linkType !== 'direct' ? (
          <p>{t('paymentMethods.success.followProcess', { partnerName: t(name) })}</p>
        ) : null}
      </div>
    </div>
  ) : null;
};

Success.propTypes = {
  channel: PropTypes.string,
};

Success.defaultProps = {
  channel: '',
};
