/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Alert,
  base,
  baseStyles,
  box,
  Button,
  colors,
  colorTheme,
  Divider,
  Icon,
  icons,
  Input,
  Loader,
  respondTo,
  Row,
  subtitleFont,
} from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../../ephemeral-link/Header';
import { useBackgroundImageUrl } from '../../hooks/useBackgroundImageUrl';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import Layout from '../CreationLayoutV2';
import { PaymentTypes } from '../payment-method-types.enum';
import { initCyberSource } from '../services/init-cybersource';
import { savePaymentMethod } from '../services/save-payment-method';

const elements = {
  cardNumber: 'card-number-container',
  cardNumberPlaceholder: 'Ex.: 4444 6666 8888 0000',
  securityCode: 'security-code-container',
  securityCodePlaceholder: 'Ex.: 123',
  dueDate: 'due-date',
  cardHolder: 'card-holder',
  payButton: 'pay-button',
};

const microformStyles = {
  input: {
    'font-size': '16px',
    'font-family': base.fontFamily,
    color: colorTheme.textDefault,
  },
  '::placeholder': {
    color: colorTheme.textPlaceholder,
  },
  ':focus': { color: colorTheme.textDefault },
  ':disabled': { cursor: 'not-allowed' },
  valid: { color: colorTheme.textDefault },
  invalid: { color: colors.red.dark },
};

const styles = {
  ...box,
  gridColumn: 4,
  paddingTop: 25,
  paddingBottom: 50,
  maxWidth: 650,
  paddingLeft: 18,
  paddingRight: 18,
  [respondTo('xs', 'sm')]: {
    width: '100%',
  },
  [respondTo('lg')]: {
    minWidth: 500,
  },
  '.title': {
    marginTop: 20,
    fontSize: '2.4em',
    lineHeight: '40px',
    marginBottom: 20,
  },
  '.detail': {
    ...subtitleFont('sm'),
    textTransform: 'uppercase',
    color: colors.gray.dark,
  },
  '.coverage-detail': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  '.coverage-detail-text-container': {
    display: 'flex',
    flexDirection: 'column',
    height: '40px',
    alignItems: 'start',
  },
  '.coverage-detail-label': {
    fontSize: '13px',
    fontWeight: 300,
  },
  '.coverage-detail-text': {
    fontFamily: base.fontFamily,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '17px',
  },
  '.preload-name-button': {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1rem',
    marginBottom: '1.5em',
    marginTop: '1em',
    height: '1rem',
    gap: 10,
    background: 'none',
    padding: 0,
    textDecoration: 'underline',
    color: colors.cyan.medium,
  },
  '.preload-name-button:active': {
    color: colors.cyan.dark,
  },
  '.preload-name-button--disabled': {
    textDecoration: 'none',
    cursor: 'default',
  },
  '.coverage-detail-amount': {
    fontFamily: 'Gilroy',
    fontSize: '21px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '16px',
  },
  '.form-title': {
    fontFamily: 'Gilroy',
    fontSize: 20,
    lineHeight: '30px',
    marginTop: 30,
    marginBottom: 30,
  },
  '.form-content': {
    [respondTo('sm')]: {
      paddingTop: 19,
      paddingLeft: 81,
      paddingRight: 81,
      paddingBottom: 24,
    },
  },
  label: {
    textTransform: 'uppercase',
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1,
    marginBottom: 4,
    color: colors.gray.dark,
  },
  '.flex-microform': {
    ...baseStyles.input,
    height: 48,
  },
  '.error-message': {
    marginBottom: 16,
  },
  '.recurring-payments-alert': {
    marginBottom: 23,
  },
};

const formatAmount = (amount) => {
  const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format;
  return formatter(amount);
};

const useCoverageName = () => {
  const { t } = useTranslation(['paymentMethods']);
  const product = useQueryParams().get('product');
  return t(`paymentMethods.coverageName.products.${product}`, {
    defaultValue: t('paymentMethods.coverageName.default'),
  });
};

const isNameSameAsInsuredButtonMapping = {
  [true]: {
    icon: icons.ok,
    className: 'preload-name-button preload-name-button--disabled',
    text: 'Mesmo nome do segurado',
  },
  [false]: {
    icon: icons.refresh,
    className: 'preload-name-button',
    text: 'Usar mesmo nome do segurado',
  },
};

export const CyberSourceBrick = ({
  linkId,
  insuredPartyName,
  country,
  channel,
  amount,
  personId,
}) => {
  const { t, ready } = useSelfServiceTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorFields, setErrorFields] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [cardholderName, setCardholderName] = useState();
  const backgroundImageUrl = useBackgroundImageUrl(channel);
  const coverageName = useCoverageName();
  const updatePaymentMethod = amount === 0;

  const isNameSameAsInsured = cardholderName === insuredPartyName;

  const onSubmit = useCallback(
    async (error, formValues) => {
      if (error) {
        setErrorMessage(error.message);
        setErrorFields(error.fields);
        return;
      }

      setSubmitting(true);

      const saveResult = await savePaymentMethod({
        type: PaymentTypes.CYBERSOURCE_PRU_BR,
        linkId,
        country,
        channel,
        token: formValues.token,
        contextKey: formValues.contextKey,
        brandName: formValues.brandName,
        cardHolder: formValues.cardHolder,
        dueDate: formValues.dueDate,
        updatePaymentMethod,
      });

      setSubmitting(false);

      if (!saveResult.ok) {
        setErrorMessage(saveResult.errorMessage);
        setErrorFields(saveResult.errorFields);
        return;
      }

      window.location = saveResult.redirectUrl;
    },
    [country, channel, linkId, updatePaymentMethod],
  );

  useEffect(() => {}, [personId]);

  useEffect(() => {
    initCyberSource({ styles: microformStyles, elements, onSubmit }).then((result) => {
      if (!result.ok) {
        setErrorMessage(result.errorMessage);
      }
    });
  }, [onSubmit]);

  if (!ready) {
    return <Loader />;
  }

  return (
    <Layout backgroundImageUrl={backgroundImageUrl}>
      <Header channel={channel} justifyContent={'start'} />
      <Row className="form-container">
        <div css={styles}>
          <Row>
            <div className="title">
              <div>
                {updatePaymentMethod
                  ? t('paymentMethods.pageTitle.updatePaymentMethod')
                  : t('paymentMethods.pageTitle.CYBERSOURCE_PRU_BR')}
              </div>
            </div>
          </Row>
          {!updatePaymentMethod && (
            <Fragment>
              <p className="detail">detalhe</p>
              <Divider />
              <div className="coverage-detail">
                <span style={{ display: 'flex', alignItems: 'center', gap: 9 }}>
                  <Icon icon={icons.basket} color={colors.gray.dark} size={24} />
                  <div className="coverage-detail-text-container">
                    <span className="coverage-detail-label">Nome cobertura</span>
                    <span className="coverage-detail-text">{coverageName}</span>
                  </div>
                </span>
                <span className="coverage-detail-amount">{formatAmount(amount)}/mês</span>
              </div>
            </Fragment>
          )}
          <Divider />
          <div className="form-content">
            {errorMessage && (
              <Alert className="error-message" type="error" text={t(errorMessage)} />
            )}
            <p className="form-title">Insira os dados do cartão</p>
            <Input
              id={elements.cardNumber}
              name="cardNumber"
              label={t('paymentMethods.createPaymentMethod.cardNumber')}
              tag="div"
              error={errorFields?.cardNumber && t(errorFields.cardNumber)}
            />
            <Input
              id={elements.dueDate}
              name="dueDate"
              label={t('paymentMethods.createPaymentMethod.dueDate')}
              mask="99/99"
              maskChar="_"
              placeholder="Ex.: 11/25"
              error={errorFields?.dueDate && t(errorFields.dueDate)}
            />
            <Input
              id={elements.securityCode}
              name="securityCode"
              label={'Código de segurança'}
              tag="div"
              error={errorFields?.securityCode && t(errorFields.securityCode)}
            />
            <Input
              id={elements.cardHolder}
              name="cardHolder"
              value={cardholderName}
              onChange={(event) => setCardholderName(event.target.value)}
              label={t('paymentMethods.createPaymentMethod.holderName')}
              placeholder="Como escrito no cartão"
              error={errorFields?.cardHolder && t(errorFields.cardHolder)}
            />
            {insuredPartyName && (
              <Button
                className={isNameSameAsInsuredButtonMapping[isNameSameAsInsured].className}
                disabled={isNameSameAsInsured}
                onClick={() => setCardholderName(insuredPartyName)}
              >
                <Icon
                  icon={isNameSameAsInsuredButtonMapping[isNameSameAsInsured].icon}
                  size={15}
                  color={colors.cyan.medium}
                />
                {isNameSameAsInsuredButtonMapping[isNameSameAsInsured].text}
              </Button>
            )}
            <Button id={elements.payButton} block color="success" disabled={isSubmitting}>
              {updatePaymentMethod
                ? t('paymentMethods.buttons.saveNewPaymentMethod')
                : t('paymentMethods.buttons.pay')}
            </Button>
          </div>
        </div>
      </Row>
    </Layout>
  );
};

CyberSourceBrick.propTypes = {
  linkId: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  channel: PropTypes.string,
  personId: PropTypes.string.isRequired,
  insuredPartyName: PropTypes.string,
  amount: PropTypes.number,
};
