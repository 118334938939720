import { Loader } from '@123-front/ui-kit';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryParams } from '../hooks/useQueryParams';
import Create from '../paymentMethods/Create';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';

const Status = {
  PENDING: 'PENDING',
  VALID: 'VALID',
  INVALID: 'INVALID',
};

const useEphemeralLink = (linkId) => {
  const [linkCheckStatus, setLinkCheckStatus] = useState(Status.PENDING);
  const [firstInstallmentAmount, setFirstInstallmentAmount] = useState();
  const [personId, setPersonId] = useState();
  const [insuredPartyName, setInsuredPartyName] = useState();

  useEffect(() => {
    if (!linkId) {
      setLinkCheckStatus(Status.INVALID);
      return;
    }

    axios.get(`payments/ephemeral-links?link=${linkId}`).then(
      (res) => {
        setInsuredPartyName(res.data.personName);
        if (res.data.firstInstallment) {
          setFirstInstallmentAmount(res.data.firstInstallment.amount);
          setPersonId(res.data.personId);
        }
        setLinkCheckStatus(Status.VALID);
      },
      () => setLinkCheckStatus(Status.INVALID),
    );
  }, [linkId, setLinkCheckStatus]);

  return {
    insuredPartyName,
    linkCheckStatus,
    linkId,
    firstInstallmentAmount,
    personId,
  };
};

export const LinkHandler = ({ match }) => {
  const channel = match.params.channel || '';
  const { i18n } = useSelfServiceTranslation();
  const country = useMemo(() => i18n.language.toUpperCase(), [i18n]);
  const queryParams = useQueryParams();

  const { linkCheckStatus, linkId, firstInstallmentAmount, personId, insuredPartyName } =
    useEphemeralLink(queryParams.get('link'));

  if (linkCheckStatus === Status.PENDING) {
    return <Loader />;
  }

  if (linkCheckStatus === Status.VALID) {
    return (
      <Create
        linkId={linkId}
        country={country}
        channel={channel}
        firstInstallmentAmount={firstInstallmentAmount}
        insuredPartyName={insuredPartyName}
        personId={personId}
      />
    );
  }

  queryParams.delete('link');

  return <Redirect to={{ pathname: './invalid-link', search: queryParams.toString() }} />;
};

LinkHandler.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      channel: PropTypes.string,
    }),
  }),
};

LinkHandler.defaultProps = {
  match: {
    params: {
      channel: '',
    },
  },
};
