const baseUrlLogos = 'https://branding.s3.us-west-2.amazonaws.com';

const brandedChannels = {
  supervielle: {
    name: 'Supervielle',
    headerLogos: [`${baseUrlLogos}/logotypes/partners/color/logo_supervielle.svg`],
    linkType: 'email',
    invalidLink: 'invalidLink',
    contactType: 'contactAnAgent',
    includeContact: true,
    primaryContactIntro: 'callUs',
    primaryContact: '0800 3451 186',
    primaryContactTarget: 'tel:08003451186',
    secondaryContactIntro: 'orWriteUs',
    secondaryContact: 'supervielle.soporte@123seguro.com',
    secondaryContactTarget: 'mailto:supervielle.soporte@123seguro.com',
    includeTermsConditions: false,
  },
  demo: {
    name: 'Prudential',
    headerLogos: [`${baseUrlLogos}/logotypes/insurers/color/seguros-prudential.svg`],
    linkType: 'direct',
    invalidLink: 'invalidLink',
    contactType: 'weWillContactYou',
    includeContact: true,
    primaryContactIntro: 'continueOnWhatsapp',
    primaryContact: '+56 9 3862 8428',
    primaryContactTarget: 'https://wa.me/56938628428',
    secondaryContactIntro: 'orCallUs',
    secondaryContact: '55-1103-7000',
    secondaryContactTarget: 'tel:5511037000',
    includeTermsConditions: true,
  },
  'demo-us': {
    name: 'Prudential',
    headerLogos: [`${baseUrlLogos}/logotypes/insurers/color/seguros-prudential.svg`],
    linkType: 'direct',
    invalidLink: 'invalidLink',
    contactType: 'weWillContactYou',
    includeContact: true,
    primaryContactIntro: 'continueOnWhatsapp',
    primaryContact: '+56 9 3862 8428',
    primaryContactTarget: 'https://wa.me/56938628428',
    secondaryContactIntro: 'orCallUs',
    secondaryContact: '55-1103-7000',
    secondaryContactTarget: 'tel:5511037000',
  },
  prudential: {
    name: 'Prudential',
    headerLogos: [`${baseUrlLogos}/logotypes/insurers/color/seguros-prudential.svg`],
    linkType: 'direct',
    invalidLink: 'invalidLink',
    contactType: 'weWillContactYou',
    includeContact: true,
    primaryContactIntro: 'continueOnWhatsapp',
    primaryContact: '+56 9 3862 8428',
    primaryContactTarget: 'https://wa.me/56938628428',
    secondaryContactIntro: 'orCallUs',
    secondaryContact: '55-1103-7000',
    secondaryContactTarget: 'tel:5511037000',
    includeTermsConditions: true,
  },
  'pru-serasa': {
    name: 'Prudential',
    headerLogos: [`${baseUrlLogos}/logotypes/partners/white/logo_serasa_prudential_linea.svg`],
    headerBackgroundColor: '#E40781',
    useNewDesign: 1,
    linkType: 'directAlternative',
    invalidLink: 'invalidLinkAlternative',
    includeContact: false,
    includeTermsConditions: true,
    backgroundImageUrl: 'https://branding.123seguro.com/images/img_wizard_background.jpg',
    returnPath: 'partner/serasa/acidentes-pessoais/cotar',
    products: {
      'acidentes-pessoais': {
        backgroundImageUrl: 'https://branding.123seguro.com/images/img_wizard_background.jpg',
        returnPath: 'partner/serasa/acidentes-pessoais/cotar',
      },
      'doencas-tropicais': {
        backgroundImageUrl:
          'https://branding.123seguro.com/landings/serasa_dolencias/img_background_wizard.jpg',
        returnPath: 'partner/serasa/doencas-tropicais/cotar',
      },
    },
    primaryContact: '+55 (11) 3777-5860',
    primaryContactTarget: 'tel:551137775860',
  },
};

const defaultChannel = {
  name: '123Seguro',
  headerLogos: [`${baseUrlLogos}/logotypes/123/color/logo_123seguro.svg`],
  linkType: 'email',
  contactType: 'contactAnAgent',
  includeContact: true,
  invalidLink: 'invalidLink',
  primaryContactIntro: 'continueOnWhatsapp',
  primaryContact: '+54 9 11-6038-9311',
  primaryContactTarget: 'https://wa.me/5491160389311',
  secondaryContactIntro: 'orCallUs',
  secondaryContact: '0800-3456-123',
  secondaryContactTarget: 'tel:08003456123',
  includeTermsConditions: false,
  localOverrides: {
    br: {
      primaryContact: '+55 11 94586-1008',
      primaryTarget: 'https://wa.me/5511945861008',
      secondaryContact: '0800-888-1105',
      secondaryTarget: 'tel:08008881105',
    },
    cl: {
      primaryContact: '+56 9 3862 8428',
      primaryTarget: 'https://wa.me/56938628428',
      secondaryContact: '800-914-106',
      secondaryTarget: 'tel:800914106',
    },
  },
};

export const getChannelData = (channel, countryCode) => {
  const channelData = brandedChannels[channel] || defaultChannel;
  const overrides =
    channelData.localOverrides && channelData.localOverrides[countryCode]
      ? channelData.localOverrides[countryCode]
      : {};

  return {
    name: channelData.name,
    headerLogos: channelData.headerLogos,
    headerBackgroundColor: channelData.headerBackgroundColor,
    backgroundImageUrl: channelData.backgroundImageUrl,
    useNewDesign: channelData.useNewDesign,
    linkType: channelData.linkType,
    returnPath: channelData.returnPath,
    invalidLink: channelData.invalidLink,
    includeContact: channelData.includeContact,
    contactType: channelData.contactType,
    primaryContactIntro: channelData.primaryContactIntro,
    primaryContact: channelData.primaryContact,
    primaryContactTarget: channelData.primaryContactTarget,
    secondaryContactIntro: channelData.secondaryContactIntro,
    secondaryContact: channelData.secondaryContact,
    secondaryContactTarget: channelData.secondaryContactTarget,
    includeTermsConditions: channelData.includeTermsConditions,
    products: channelData.products || {},
    ...overrides,
  };
};
